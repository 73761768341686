<template>
  <div class="wrapper__info-legal" v-if="userInfolegal">
    <div class="wrapper__info-legal-image" v-if="userInfolegal.business_logo">
      <img :src="userInfolegal.business_logo" alt="" />
    </div>
    <div class="wrapper__info-legal-content">
      <p class="text-pre-wrap" v-html="userInfolegal.legal"></p>
    </div>
  </div>
</template>

<script>
import usersService from "./services/usersService";

export default {
  name: "UserInforLegalPage",
  data() {
    return {
      userInfolegal: null
    };
  },
  props: ["query"],
  methods: {
    async getUserInfoLegal({ userUniqId, userBusinessid }) {
      try {
        this.$store.dispatch("toggleRequestLoading");
        let response = await usersService
          .getUserInfoLegal({
            uniq_id: userUniqId,
            id: userBusinessid
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response.data.success) {
          this.userInfolegal = response.data.data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  mounted() {
    const { userUniqId, userBusinessid } = this.$route.params;
    this.getUserInfoLegal({ userUniqId, userBusinessid });
  }
};
</script>

<style lang="scss" scoped>
.wrapper__info-legal {
  padding: 1rem 4rem;
  .wrapper__info-legal-image {
    img {
      width: 350px;
      margin: auto;
      display: block;
    }
  }
}
</style>
